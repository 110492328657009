var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { getRecoil } from "recoil-nexus";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import organizationIdSelector from "../atoms/organizationIdSelector";
import { GroupType } from "../data/MessageData";
import { consola } from "consola";
var useMessageGroupData = function (_ref) {
    var users = _ref.users, order = _ref.order, groupType = _ref.groupType, direction = _ref.direction, onSnapshot = _ref.onSnapshot;
    var organizationId = getRecoil(organizationIdSelector);
    React.useEffect(function () {
        var groupRef;
        if (groupType === GroupType.single) {
            groupRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups").where("users", "array-contains-any", users);
        }
        else {
            groupRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups");
        }
        if (groupType) {
            groupRef = groupRef.where("type", "==", groupType);
        }
        if (order) {
            groupRef = groupRef.orderBy(order, direction);
        }
        var subscription = groupRef.onSnapshot(function (snapshot) {
            consola.info("++++++ SNAPSHOT -> useMessageGroupData", groupType);
            if (onSnapshot && snapshot) {
                var groupDataArray = snapshot.docs.map(function (d) { return __assign(__assign({}, d.data()), { ref: d.ref }); });
                onSnapshot(groupDataArray.filter(function (d) {
                    if ("isArchived" in d) {
                        return !d.isArchived || d.isArchived === false;
                    }
                    return true;
                }));
            }
        });
        return function () { subscription(); };
    }, [organizationId, onSnapshot, users, order, direction, groupType]);
    return true;
};
export default useMessageGroupData;
