import { useState, useEffect } from "react";

import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  where,
} from "firebase/firestore";

const useCoaches = ({ organizationId, includeDisabled = true }) => {
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    const db = getFirestore();
    let coachesCollection = collection(
      db,
      "organizations",
      organizationId,
      "coaches",
    );

    if (!includeDisabled) {
      coachesCollection = query(
        coachesCollection,
        where("status", "==", "active"),
      );
    }

    onSnapshot(coachesCollection, (snapshot) => {
      const clients = snapshot.docs.map((doc) => doc.data());
      setCoaches(clients);
      setLoading(false);
    });
  }, [organizationId, includeDisabled]);

  return { coaches, loading };
};

export default useCoaches;
