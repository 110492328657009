import React from "react";
import { Typography } from "@mui/material";
import colors from "../../themes/Colors";

export default React.memo(
  ({ title, count, showAlertCount, colorAlertCount = true }) => {
    if (showAlertCount && count > 0) {
      return (
        <Typography
          style={{ color: colorAlertCount ? colors.mainTitleColor : "black" }}
          variant="h5"
        >{`${title} (${count})`}</Typography>
      );
    }
    return <Typography variant="h5">{`${title}`}</Typography>;
  },
);
