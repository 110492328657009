import React from "react";
import { Box, Typography, Stack, Divider, Grid } from "@mui/material";
import moment from "moment";

const ClientReportSummary = ({ report }) => {
  if (!report) {
    return null;
  }

  const { clientData } = report;
  const { statistics } = clientData;

  const birthDateString = moment(clientData.birthDate).format("MM/YYYY");

  return (
    <Box sx={{ mt: 1 }}>
      <Stack>
        <Typography style={{ color: "black", fontSize: 24, fontWeight: "600" }}>
          Client Summary
        </Typography>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <Typography>Birthday: {birthDateString}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              Goal:{" "}
              {statistics.goal && statistics.goal.length > 0
                ? statistics.goal
                : "No goal"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              Starting weight:{" "}
              {statistics && statistics.startingWeight
                ? statistics.startingWeight.toFixed(2)
                : 0}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              Last recorded weight:{" "}
              {statistics && statistics.currentWeight
                ? statistics.currentWeight.toFixed(2)
                : 0}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
};

export default ClientReportSummary;
