import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import moment from "moment";

import ReportTableContainerTitle from "../atoms/ReportTableContainerTitle";
import NoReportsView from "../molecules/NoReportsView";
import { DeleteButton } from "../../atoms/EditButton";
import AvatarName from "../molecules/AvatarName";
import { ReportStates } from "../../hooks/useReportHistory";

const ReportDataGrid = ({ name, reports, columns, onRowClick }) => {
  const theme = useTheme();

  return (
    <DataGrid
      autoHeight
      pageSize={15}
      rowsPerPageOptions={[15, 25, 50]}
      sx={{
        ".MuiDataGrid-sortIcon": {
          color: theme.palette.primary.main,
        },
        ".MuiDataGrid-filterIcon": {
          color: theme.palette.primary.main,
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        ".MuiDataGrid-menuIconButton": {
          color: theme.palette.primary.main,
        },
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: "8px",
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
      }}
      columnVisibilityModel={
        localStorage.getItem("reportDataGridColumns_" + name) || {}
      }
      disableSelectionOnClick
      rows={reports}
      columns={columns}
      getRowHeight={() => {
        return "auto";
      }}
      onRowClick={onRowClick}
      onColumnVisibilityModelChange={(model) => {
        localStorage.setItem(
          "reportDataGridColumns_" + name,
          JSON.stringify(model),
        );
        // setVisibilityModel(model);
      }}
    />
  );
};

const ReportTableContainer = ({
  loading = true,
  state = ReportStates.unprocessed,
  title,
  reports,
  showAlertCount,
  reportClicked,
  deleteReportClicked,
  onSeeProcessed,
}) => {
  function getPeriod(report) {
    try {
      let start = "";
      let end = "";

      if (report.startDate) {
        start = moment(report.startDate?.toDate());
      }
      if (report.endDate) {
        end = moment(report.endDate?.toDate());
      }
      return `${start.format("MM/DD")} - ${end.format("MM/DD")}`;
    } catch {
      return "";
    }
  }

  function getHoursSinceCreatedOn(report) {
    const createdOn = moment(report.createdOn);
    const now = moment();
    const duration = moment.duration(now.diff(createdOn));
    const hours = duration.asHours().toFixed(0);
    return hours;
  }

  function getCreatedOn(report) {
    const createdOn = moment(new Date(report.createdOn)).clone();
    return createdOn.format("MM/DD/YYYY");
  }

  const columns = React.useMemo(() => {
    const result = [
      {
        field: "avatar",
        headerName: "",
        width: 50,
        hideable: false,
        headerAlign: "left",
        align: "left",
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <AvatarName
                showName={false}
                url={params.row.userData.avatarUrl}
                size={32}
                firstName={params.row.clientFirstName}
                lastName={params.row.clientLastName}
              />
            </Box>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
        valueGetter: (params) => {
          return `${params.row.clientFirstName} ${params.row.clientLastName}`;
        },
      },
      {
        field: "period",
        headerName: "Period",
        minWidth: 200,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
        valueGetter: (params) => {
          return getPeriod(params.row);
        },
      },
      {
        field: "createdOn",
        headerName: "Created",
        minWidth: 300,
        hideable: false,
        headerAlign: "left",
        align: "left",
        type: "dateTime",
        defaultSort: "asc",
        valueGetter: (params) => {
          return params.row.createdOn;
        },
        renderCell: (params) => {
          return getCreatedOn(params.row);
        },
      },
    ];

    if (state === ReportStates.unprocessed) {
      result.push({
        field: "elapsed",
        headerName: "Hours since Creation",
        minWidth: 300,
        hideable: false,
        headerAlign: "left",
        align: "left",
        type: "number",
        defaultSort: "asc",
        renderCell: (params) => {
          const hours = getHoursSinceCreatedOn(params.row);
          if (hours > 24) {
            return <Typography color="error">{hours}</Typography>;
          }
          return <Typography>{hours}</Typography>;
        },
      });
    }

    result.push({
      minWidth: 35,
      headerAlign: "center",
      align: "center",
      field: "delete",
      headerName: "",
      sortable: false,
      hideable: false,
      filterable: false,
      renderCell: (params) => {
        if (params.row.status === "unprocessed") {
          return null;
        }
        return (
          <Stack
            spacing={0}
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <DeleteButton
              onClick={(event) => {
                event.ignore = true;
                event.stopPropagation();
                deleteReportClicked(params.row);
              }}
            />
          </Stack>
        );
      },
    });

    return result;
  }, [state]);

  const handleOnSeeProcessed = React.useCallback(
    (type) => {
      if (onSeeProcessed) {
        onSeeProcessed(type);
      }
    },
    [onSeeProcessed],
  );

  if (loading) {
    return null;
  }

  if (reports.length === 0) {
    // Show no reports UI.
    return (
      <NoReportsView state={state} onSeeProcessed={handleOnSeeProcessed} />
    );
  }

  return (
    <Box>
      {reports.length > 0 && (
        <>
          <Box style={{ marginTop: 40, marginBottom: 20 }}>
            <ReportTableContainerTitle
              title={title}
              tier="tier1"
              showAlertCount={showAlertCount}
              count={reports.length}
            />
          </Box>

          <ReportDataGrid
            name="tier1"
            reports={reports}
            columns={columns}
            onRowClick={(params) => {
              reportClicked(params.row);
            }}
          />
        </>
      )}
    </Box>
  );
};

export default ReportTableContainer;
