import React from "react";
import { Box, Typography, Divider, Breadcrumbs, Link } from "@mui/material";

import CoachDescription from "./CoachDescription";
import AddCoachDialog from "./AddCoachDialog";
import CoachData from "../../../../data/CoachData";
import CoachConnectCode from "./CoachConnectCode";
import ClientSummary from "../clients/ClientSummary";
import useEvents from "../../../../hooks/useEvents";

export default React.memo((props) => {
  const [coach, setCoach] = React.useState(props.coach);
  const [mode, setMode] = React.useState("list");
  const [editCoachOpen, setEditCoachOpen] = React.useState(false);

  const selectedClient = React.useRef();
  const events = useEvents();

  React.useEffect(() => {
    setCoach(props.coach);
  }, [props.coach, props.update]); // eslint-disable-line

  React.useEffect(() => {
    const subscription = CoachData.getCoachSubscription(
      props.coach.uid,
      (snapshot) => {
        setCoach(snapshot);
      },
    );

    return () => {
      if (subscription) {
        subscription();
      }
    };
  }, [props.coach]);

  if (mode === "list") {
    return (
      <Box
        style={{
          margin: 20,
        }}
      >
        <Box>
          <Breadcrumbs separator=">">
            <Link
              onClick={() => {
                if (props.onBackClick) {
                  props.onBackClick();
                }
              }}
            >
              Coaches
            </Link>
            <Typography>{`${coach.firstName} ${coach.lastName}`}</Typography>
          </Breadcrumbs>

          <Divider style={{ marginTop: 20 }}></Divider>

          <Box style={{ backgroundColor: "white", padding: 20, marginTop: 20 }}>
            <CoachDescription
              coach={coach}
              userData={props.userData}
              editClicked={() => {
                setEditCoachOpen(true);
                events.logButtonClick({
                  buttonName: "Edit Coach",
                  pageName: "Coaches",
                  tabName: coach.uid,
                });
              }}
              avatarUpdated={async () => {
                await CoachData.editCoach(coach.uid, {
                  avatarChangedOn: new Date(),
                });
              }}
            />
            <CoachConnectCode
              style={{ marginTop: 12, width: "60%" }}
              coach={coach}
            />
          </Box>
        </Box>

        <AddCoachDialog
          mode="edit"
          coach={props.coach}
          userData={props.userData}
          open={editCoachOpen}
          onDialogClosed={(coachData) => {
            setEditCoachOpen(false);
            if (coachData) {
              CoachData.editCoach(props.coach.uid, coachData);
            }
          }}
        />
      </Box>
    );
  }

  if (mode === "details") {
    return (
      <ClientSummary
        breadCrumb={`${props.coach.firstName} ${props.coach.lastName}`}
        uid={selectedClient.current.uid}
        onBackClick={() => {
          selectedClient.current = null;
          setMode("list");
        }}
      />
    );
  }
});
