import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import RedBorderButton from "../../../../components/RedBorderButton";
import { getPeriod } from "../../../../components/LibraryFunctions";
import ClientReportSummary from "./ClientReportSummary";
import ProtocolSummary from "./ProtocolSummary";
import NutritionSummary from "./NutritionSummary";

const ReportDescription = ({ report, handleMarkProcessed }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"} spacing={1}>
          <Typography
            style={{
              fontSize: 32,
              fontWeight: 700,
              marginTop: 6,
              fontFamily: "Lato",
              fontStyle: "normal",
              paddingLeft: 30,
            }}
          >
            Report Overview: {getPeriod(report)}
          </Typography>
        </Stack>
        <Box
          flexDirection={"column"}
          textAlign={"right"}
          justifyContent={"flex-end"}
          flexShrink={0}
          marginTop={0}
          sx={{ display: "inline-flex", justifyContent: "flex-end" }}
        >
          <RedBorderButton
            onClick={() => {
              if (handleMarkProcessed) {
                handleMarkProcessed(report);
              }
            }}
          >
            {report.status === "unprocessed"
              ? `Mark as Processed`
              : "Mark as Unprocessed"}
          </RedBorderButton>
        </Box>
      </Box>
      <Stack direction={"column"} style={{ paddingInline: 30 }}>
        <ClientReportSummary report={report} />
        <ProtocolSummary report={report} />
        <NutritionSummary report={report}></NutritionSummary>
      </Stack>
    </Box>
  );
};

export default ReportDescription;
