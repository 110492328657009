import firebase from "firebase/compat/app";
import { useEffect } from "react";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import moment from "moment";
import { consola } from "consola";
import { useRecoilValue, atom, useRecoilState } from "recoil";
import userDataState from "../atoms/userDataAtom";
import organizationIdState from "../atoms/organizationIdAtom";

export const ReportStates = {
  processed: "processed",
  unprocessed: "unprocessed",
  any: "any",
};

export const CurrentReportState = atom({
  key: "CurrentReportState",
  default: ReportStates.unprocessed,
});

export const CurrentReportFilter = atom({
  key: "CurrentReportFilter",
  default: {
    state: ReportStates.unprocessed,
    start: moment().subtract(14, "days").startOf("day"),
    end: moment().endOf("day"),
    limit: 100,
  },
});

export const Reports = atom({
  key: "Reports",
  default: [],
});

const useReportHistory = () => {
  const [reports, setReports] = useRecoilState(Reports);
  const currentReportFilter = useRecoilValue(CurrentReportFilter);

  const coach = useRecoilValue(userDataState);

  const organizationId = useRecoilValue(organizationIdState);

  useEffect(() => {
    if (!coach || !organizationId) {
      return;
    }
    let reportRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("reports")
      .where("coaches", "array-contains", coach.uid)
      .where("status", "==", currentReportFilter.state);

    if (
      currentReportFilter.start &&
      currentReportFilter.state === ReportStates.processed
    ) {
      reportRef = reportRef.where(
        "createdOn",
        ">=",
        currentReportFilter.start.toDate(),
      );
    }
    if (
      currentReportFilter.end &&
      currentReportFilter.state === ReportStates.processed
    ) {
      reportRef = reportRef.where(
        "createdOn",
        "<=",
        currentReportFilter.end.toDate(),
      );
    }

    if (currentReportFilter.state === ReportStates.unprocessed) {
      reportRef = reportRef.orderBy("createdOn", "asc");
    } else {
      reportRef = reportRef.orderBy("createdOn", "desc");
    }

    if (currentReportFilter.limit) {
      reportRef = reportRef.limit(currentReportFilter.limit);
    }

    const unsubscribe = reportRef.onSnapshot(
      (snapshot) => {
        consola.info("++++++ SNAPSHOT -> ReportData: useReportHistory");
        setReports(
          snapshot.docs.map((d) => {
            return {
              ...d.data(),
              createdOn: d.data().createdOn.toDate(),
            };
          }),
        );
      },
      (error) => {
        consola.error("++++++ ERROR -> ReportData: useReportHistory", error);
      },
    );

    return () => {
      consola.info("++++++ UNSUBSCRIBE -> ReportData: useReportHistory");
      unsubscribe();
    };
  }, [coach?.uid, organizationId, currentReportFilter]);

  return { reports };
};

export default useReportHistory;
