import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import "firebase/compat/remote-config";
import "firebase/compat/performance";

import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getPerformance } from "firebase/performance";
import { getRemoteConfig } from "firebase/remote-config";

import testFirebaseConfig from "./firebaseConfig.sit.js";
import prodFirebaseConfig from "./firebaseConfig.prod.js";
import { consola } from "consola";

let firebaseConfig;

// eslint-disable-next-line no-undef
if (process.env.REACT_APP_ENV === "test") {
  consola.log("Test Environment - Production mode");
  firebaseConfig = testFirebaseConfig;
} else {
  consola.log("Production Environment - Production mode");
  firebaseConfig = prodFirebaseConfig;
}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
export const performance = getPerformance(app);
export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
// idleTimeout is in minutes, sessionTimeoutWarning is in seconds
remoteConfig.defaultConfig = {
  showRecipeGenerator: true,
  idleTimeoutProd: 10,
  idleTimeoutDev: 10,
  sessionTimeoutWarning: 60,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

consola.log("Firebase App initialized", window.location.hostname);

// eslint-disable-next-line no-undef
consola.log("Emulator mode", process.env.REACT_APP_EMULATOR);
const emulatorHost = "127.0.0.1";
const localDevHost = "localhost";
if (
  window.location.hostname === emulatorHost ||
  (window.location.hostname === localDevHost &&
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_EMULATOR === "true")
) {
  consola.log("Using Firebase Emulators");

  firebase.auth().useEmulator(`http://${emulatorHost}:9099`);
  firebase.firestore().useEmulator(emulatorHost, 8082);
  firebase.storage().useEmulator(emulatorHost, 9199);
  // firebase.performance().useEmulator(`http://${emulatorHost}:5000`);

  connectFirestoreEmulator(db, emulatorHost, 8082);
  connectAuthEmulator(auth, `http://${emulatorHost}:9099`);
  connectStorageEmulator(storage, emulatorHost, 9199);
}

firebase.auth().setPersistence("local");

firebase.analytics.isSupported().then((isSupported) => {
  if (isSupported) {
    firebase.analytics();
  }
});

export default firebaseApp;
