import firebase from "firebase/compat/app";
import { useEffect } from "react";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import _ from "underscore";
import { consola } from "consola";
import { useRecoilValue, atom, useRecoilState } from "recoil";
import organizationIdState from "../atoms/organizationIdAtom";

export const Protocols = atom({
  key: "Protocols",
  default: [],
});

export function findProtocol({ protocolId, protocols }) {
  const protocol = _.find(protocols, { id: protocolId });
  return protocol;
}

const useProtocolsSubscription = () => {
  const [protocols, setProtocols] = useRecoilState(Protocols);

  const organizationId = useRecoilValue(organizationIdState);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    const reportRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("protocols");

    const unsubscribe = reportRef.onSnapshot(
      (snapshot) => {
        consola.info(
          "++++++ SNAPSHOT -> ProtocolData: useProtocolsSubscription",
        );
        setProtocols(
          snapshot.docs.map((d) => {
            return {
              ...d.data(),
            };
          }),
        );
      },
      (error) => {
        consola.error(
          "++++++ ERROR -> ProtocolData: useProtocolsSubscription",
          error,
        );
      },
    );

    return () => {
      consola.info(
        "++++++ UNSUBSCRIBE -> ProtocolData: useProtocolsSubscription",
      );
      unsubscribe();
    };
  }, [organizationId]);

  return { protocols };
};

export default useProtocolsSubscription;
