import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Box,
  Typography,
  IconButton,
  Snackbar,
  Stack,
  Tooltip,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";

import ClientData from "../../../../data/ClientData";
import useEmail from "../../../../hooks/useEmail";
import RedBorderButton from "../../../../components/RedBorderButton";

const ConnectCode = ({
  uid,
  connectCode,
  connectCodeEmailSentOn,
  organizationName,
  organizationId,
}) => {
  const [connectCodeLink, setConnectCodeLink] = React.useState();

  const [error, setError] = React.useState({
    open: false,
    message: "",
    state: "success",
  });

  const { sendConnectCodeEmailTemplate } = useEmail({
    uid,
  });

  function handleCopy() {}

  React.useEffect(() => {
    async function getConnectCodeLink() {
      const result = await ClientData.getDynamicLink({
        connectCode: connectCode,
        organizationName: organizationName,
        organizationId: organizationId,
        type: "client",
      });
      setConnectCodeLink(result ? result : undefined);
    }

    getConnectCodeLink();
  }, [connectCode, organizationId, organizationName]);

  return (
    <>
      <Box
        style={{
          marginLeft: 12,
          marginRight: 12,
          marginTop: 24,
          borderWidth: 1,
          padding: 12,
          borderStyle: "solid",
          borderColor: "lightgray",
          borderRadius: 12,
        }}
      >
        <Box style={{ marginBottom: 12, marginLeft: 30 }}>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Connect Code
          </Typography>
          <Typography style={{ fontSize: 13, width: "75%" }}>
            The connect code is a unique identifier that links your client to
            your organization. Your client will need it during registration to
            connect. Share the code with them to get started.
          </Typography>
        </Box>

        <Stack direction="row" spacing={8} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              style={{
                fontWeight: "bold",
                marginLeft: 30,
              }}
            >{`${connectCode}`}</Typography>

            <CopyToClipboard text={`${connectCode}`}>
              <Tooltip title={"Copy the code to the clipboard."}>
                <IconButton onClick={handleCopy}>
                  <FileCopyIcon htmlColor={"black"} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              style={{
                fontWeight: "bold",
              }}
            >{`${
              connectCodeLink ? connectCodeLink.shortLink : ""
            }`}</Typography>
            <CopyToClipboard
              text={`${connectCodeLink ? connectCodeLink.shortLink : ""}`}
            >
              <Tooltip title={"Copy the link to the clipboard."}>
                <IconButton onClick={handleCopy}>
                  <FileCopyIcon htmlColor={"black"} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>

            <Tooltip title={"Get a new connect code."}>
              <IconButton
                onClick={() => {
                  ClientData.resetConnectCode(uid);
                }}
              >
                <RefreshIcon htmlColor={"black"} />
              </IconButton>
            </Tooltip>
          </Stack>

          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <RedBorderButton
              style={{ width: 180 }}
              onClick={async () => {
                // check connectCodeEmailSentOn, if it exists and 1 hours has not passed, show error message
                if (
                  connectCodeEmailSentOn &&
                  moment(connectCodeEmailSentOn.toDate()).add(1, "second") >
                    moment()
                ) {
                  setError({
                    open: true,
                    message:
                      "You can only send the connect code email once every hour.",
                    state: "error",
                  });

                  return;
                }

                // const result = await sendConnectCodeEmail();
                const result = await sendConnectCodeEmailTemplate();

                if (!result) {
                  alert(
                    "This client does not have an email address. Please add an email address to this client's profile.",
                  );
                  return;
                }

                setError({
                  open: true,
                  message: "The connect code email was sent successfully",
                  state: "success",
                });
              }}
            >
              Email the Code
            </RedBorderButton>
            {connectCodeEmailSentOn && (
              <Typography style={{ fontSize: 13, width: 500 }}>
                Last sent on{" "}
                {moment(connectCodeEmailSentOn.toDate()).format("MM/YYYY")}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={error.open}
        autoHideDuration={6000}
        onClose={() => {
          setError({
            open: false,
            message: "",
            state: "success",
          });
        }}
      >
        <Alert
          onClose={() => {
            setError({
              open: false,
              message: "",
              state: "success",
            });
          }}
          severity={error.state}
        >
          {error.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ConnectCode;
