import React from "react";
import {
  Box,
  Typography,
  Stack,
  Grid,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";

import ActiveSupplements from "./ActiveSupplements";

const SupplementHistoryTaken = ({ supplementHistory }) => {
  const theme = useTheme();

  if (supplementHistory.length === 0) {
    return (
      <Box sx={{ marginTop: 1 }}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: "600",
            marginLeft: 2,
          }}
        >
          No Supplement History for this report.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {supplementHistory.map((supplement) => {
        return (
          <Box
            key={supplement.id}
            sx={{
              marginTop: 1,
              backgroundColor: "#F9F9F9",
              padding: 2,
              borderRadius: 4,
            }}
          >
            <Typography
              style={{ fontSize: 20, fontWeight: "bold", marginLeft: 90 }}
            >
              {moment(supplement.createdOn.toDate()).format("MM/YYYY")}
            </Typography>
            <Stack>
              <Box>
                {supplement.history.map((item) => {
                  return (
                    <Grid
                      key={item.id}
                      container
                      direction="row"
                      spacing={3}
                      sx={{ alignItems: "center" }}
                    >
                      <Grid item xs={6}>
                        <Typography
                          style={{ fontSize: 18, fontWeight: "bold" }}
                        >
                          {item.supplementName}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography>
                          {item.dosage} {item.frequency}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            color: item.taken
                              ? "black"
                              : theme.palette.primary.main,
                          }}
                        >
                          Taken: {item.taken ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            </Stack>
          </Box>
        );
      })}
    </Box>
  );
};

const SupplementHistory = ({ report }) => {
  const [expanded, setExpanded] = React.useState(false);

  const supplementHistory = React.useMemo(() => {
    if (report.supplementData) {
      const data = report.supplementData;
      return data;
    }
    return [];
  }, [report]);

  return (
    <Box style={{ marginTop: 20 }}>
      <Accordion
        display="flex"
        boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: "#F6F7FB", boxShadow: 3, borderRadius: 2 }}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={expanded ? <ExpandMore /> : <ExpandMore />}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              d="M2.62665 12.0533L12.0533 2.62662C15.1867 -0.493379 20.24 -0.493379 23.3733 2.62662C26.4933 5.74662 26.4933 10.8133 23.3733 13.9466L13.9467 23.3733C10.8133 26.4933 5.74665 26.4933 2.62665 23.3733C-0.493348 20.24 -0.493348 15.1866 2.62665 12.0533ZM4.51998 13.9466C3.11998 15.3333 2.65332 17.32 3.13332 19.0933L11.12 11.12L16.7733 16.7733L21.48 12.0533C23.5733 9.97329 23.5733 6.59995 21.48 4.51995C19.4 2.42662 16.0267 2.42662 13.9467 4.51995L4.51998 13.9466Z"
              fill="black"
            />
          </svg>
          <Typography
            style={{
              color: "black",
              fontSize: 24,
              fontWeight: "600",
              marginLeft: 10,
            }}
          >
            Supplement History
          </Typography>
        </AccordionSummary>

        <Box sx={{ padding: 2 }}>
          <ActiveSupplements report={report} />
          <Box sx={{ mt: 2, width: "100%", marginLeft: 2 }}>
            <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
              Supplements Taken
            </Typography>
          </Box>

          <SupplementHistoryTaken supplementHistory={supplementHistory} />
        </Box>
      </Accordion>
    </Box>
  );
};

export default SupplementHistory;
