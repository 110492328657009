import React from "react";
import { TextField, Stack, Typography } from "@mui/material";
import moment from "moment";

const limitRange = 30;

/**
 * Lets you choose a date range
 * @param {*} param0
 * @returns
 */
function DateRangePicker({
  startDate = moment(),
  endDate = moment(),
  onChangeDates,
}) {
  const [setStartDate, onSetStartDate] = React.useState(startDate);
  const [setEndDate, onSetEndDate] = React.useState(endDate);

  return (
    <Stack
      direction={"row"}
      spacing={1}
      mr={1}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography>From:</Typography>
      <TextField
        size="small"
        type="date"
        value={startDate.format("YYYY-MM-DD")}
        variant="outlined"
        onChange={(event) => {
          const value = event.target.value;
          const startDateValue = moment(value).startOf("day");

          // Ensure that the range is not more than limitRange days
          const diff = setEndDate.diff(startDateValue, "days");
          let endDate = setEndDate;
          if (diff > limitRange) {
            endDate = startDateValue.clone().add(limitRange, "days");
          }

          onSetStartDate(startDateValue);
          onSetEndDate(endDate);

          onChangeDates({ startDate: startDateValue, endDate: endDate });
        }}
      />
      <Typography>To:</Typography>
      <TextField
        size="small"
        type="date"
        value={endDate.format("YYYY-MM-DD")}
        variant="outlined"
        onChange={(event) => {
          const value = event.target.value;
          let endDateValue = moment(value).endOf("day");

          // Ensure that the range is not more than limitRange days
          const diff = endDateValue.diff(setStartDate, "days");
          let startDate = setStartDate;
          if (diff > limitRange) {
            startDate = endDateValue.clone().subtract(limitRange, "days");
          }

          // ensure the endDateValue is not before the startDate
          if (endDateValue.isBefore(startDate)) {
            endDateValue = startDate.clone().add(limitRange, "days");
          }

          onSetStartDate(startDate);
          onSetEndDate(endDateValue);

          onChangeDates({ startDate: setStartDate, endDate: endDateValue });
        }}
      />
    </Stack>
  );
}

export default DateRangePicker;
