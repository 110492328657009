import { getAccountHolderUser } from "./UserData";
import { consola } from "consola";

export const getHost = () => {
  // eslint-disable-next-line no-undef
  let forceEmulator = false;
  const emulatorHost = "127.0.0.1";
  const localDevHost = "localhost";
  if (
    window.location.hostname === emulatorHost ||
    (window.location.hostname === localDevHost &&
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_EMULATOR === "true")
  ) {
    forceEmulator = true;
  }

  if (forceEmulator) {
    return "http://127.0.0.1:5002";
  }

  if (process.env.REACT_APP_ENV === "production") {
    return "https://app.mighty45.com";
  } else {
    return "https://app.test.mighty45.com";
  }
};

export const getAPIBasePath = () => {
  const host = getHost();
  return `${host}/api/v1`;
};

export const getStripeProducts = async ({ organizationId }) => {
  if (!organizationId) {
    consola.error(new Error("Incorrect parameters"));
  }

  // eslint-disable-next-line no-undef
  const path = `${getAPIBasePath()}/stripe/subscription/`;

  const response = await fetch(`${path}${organizationId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 200) {
    const jsonData = await response.json();
    return jsonData;
  }
  consola.error(
    new Error(`Error getting stripe response - ${response.status}`),
  );
};

export const updateSubscription = async ({ organizationId }) => {
  const stubbedOut = true;
  if (stubbedOut) {
    return true;
  }

  if (!organizationId) {
    consola.error(new Error("Incorrect parameters"));
  }

  const accountHolderUser = await getAccountHolderUser({ organizationId });

  consola.log("accountHolderUser", accountHolderUser);

  const message = {
    cart: {
      name: `${accountHolderUser.firstName} ${accountHolderUser.lastName}`,
      organization_id: organizationId,
    },
  };

  const path = `${getAPIBasePath()}/stripe_cart`;

  const response = await fetch(`${path}`, {
    method: "POST",
    body: JSON.stringify(message),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 200) {
    const jsonData = await response.json();
    return jsonData;
  }
  consola.error(
    new Error(`Error getting stripe response - ${response.status}`),
  );
};
