import React from "react";
import "./App.css";
import { ConfirmProvider } from "material-ui-confirm";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";

import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";

import mainTheme from "./themes/MainTheme";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import LandingPage from "./pages/landing/LandingPage";
import SignInPage from "./pages/signin/SignInPage";
import HomePage from "./pages/home/HomePage";
import OnboardingPage from "./pages/onboarding/OnboardingPage";
import ConnectCodePage from "./pages/onboarding/ConnectCodePage";
import "./i18n";
import TrainingPlan from "./pages/home/plan/TrainingPlan";
import SubscribePage from "./pages/home/subscribe/SubscribePage";
import useWindowSize from "./hooks/useWindowSize";
import consola from "consola";

consola.level = 6;

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === "production") {
  consola.level = 0; // Disables all logs
}

Bugsnag.start({
  apiKey: "f2537d0a9e8bf06a884c8b33a509a99a",
  plugins: [new BugsnagPluginReact()],
  appVersion: "1.0.0",
});

const onError = (event) => {
  consola.error(new Error("Error captured by Bugsnag: ", event));
};

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function App() {
  useWindowSize();
  React.useEffect(() => {
    if (!("Notification" in window)) {
      //
    } else {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          //
        } else {
          //
        }
      });
    }
  }, []);

  return (
    <ErrorBoundary onError={onError}>
      <RecoilRoot>
        <RecoilNexus />
        <ThemeProvider theme={mainTheme}>
          <Router>
            <ConfirmProvider>
              <Routes>
                <Route path={"/"} element={<LandingPage />} />
                <Route path={"/signin"} element={<SignInPage />} />
                <Route path={"/home"} element={<HomePage />} />
                <Route path={"/onboarding"} element={<OnboardingPage />} />
                <Route path={"/connect"} element={<ConnectCodePage />} />
                <Route
                  path={"/plan/:organizationId/:id"}
                  element={<TrainingPlan />}
                />
                <Route path={"/subscribe"} element={<SubscribePage />} />
              </Routes>
            </ConfirmProvider>
          </Router>
        </ThemeProvider>
      </RecoilRoot>
    </ErrorBoundary>
  );
}

export default App;
