import React from "react";
import { Box, Button } from "@mui/material";
import ClientDataGrid from "./ClientDataGrid";
import { aggregatedClientsState } from "../../../../hooks/useClientsAggregate";
import { useRecoilValue } from "recoil";

const ClientTable = ({
  onAddClientClicked,
  onClientClicked,
  deleteClientClicked,
  additionalInfo,
}) => {
  const clients = useRecoilValue(aggregatedClientsState);

  return (
    <>
      {onAddClientClicked && (
        <Box
          style={{ marginRight: 24, marginBottom: 12 }}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (clients.length === 0) {
                if (onAddClientClicked) {
                  onAddClientClicked(true);
                }
                return;
              }

              if (onAddClientClicked) {
                onAddClientClicked(true);
              }
            }}
          >
            Add New Client
          </Button>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <ClientDataGrid
          additionalInfo={additionalInfo}
          clients={clients}
          onClick={(client) => {
            if (onClientClicked) {
              onClientClicked(client);
            }
          }}
          onDelete={(client) => {
            if (deleteClientClicked) {
              deleteClientClicked(client);
            }
          }}
        />
      </Box>
    </>
  );
};

export default ClientTable;
