import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { performance } from "../../../data/FirebaseApp";
import { trace } from "firebase/performance";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DateRangePicker from "../../../components/molecules/DateRangePicker";
import ReportData from "../../../data/ReportData";
import ReportSummaryDrawer from "./reports/ReportSummaryDrawer";
import ReportTableContainer from "../../../components/organisms/ReportTableContainer";
import useEvents from "../../../hooks/useEvents";
import {
  ReportStates,
  Reports,
  CurrentReportFilter,
} from "../../../hooks/useReportHistory";
import { useRecoilValue, useRecoilState } from "recoil";

const ReportPage = () => {
  const navigate = useNavigate();
  const events = useEvents();

  const t = trace(performance, "REPORT_PAGE");
  t.start();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [report, setReport] = React.useState(undefined);
  const [currentReportFilter, setCurrentReportFilter] =
    useRecoilState(CurrentReportFilter);
  const reports = useRecoilValue(Reports);

  React.useEffect(() => {
    return () => {
      if (navigate.action === "POP") {
        navigate("/home");
      }
    };
  }, [navigate]);

  React.useEffect(() => {
    firebase.analytics().logEvent("page_view", {
      page_title: "Report Page",
      page_location: "/reports",
    });
  }, []);

  t.stop();

  return (
    <Box style={{ margin: 12 }}>
      {!drawerOpen && (
        <Box display="flex" justifyContent="flex-end">
          {currentReportFilter.state === ReportStates.processed && (
            <DateRangePicker
              startDate={currentReportFilter.start}
              endDate={currentReportFilter.end}
              onChangeDates={({ startDate, endDate }) => {
                setCurrentReportFilter((previousState) => ({
                  ...previousState,
                  start: startDate,
                  end: endDate,
                }));
              }}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (currentReportFilter.state === ReportStates.unprocessed) {
                setCurrentReportFilter((previousState) => ({
                  ...previousState,
                  state: ReportStates.processed,
                }));
                events.logButtonClick({
                  buttonName: "See Recently Processed",
                  pageName: "Reports",
                });
              } else {
                setCurrentReportFilter((previousState) => ({
                  ...previousState,
                  state: ReportStates.unprocessed,
                }));
                events.logButtonClick({
                  buttonName: "See Active Reports",
                  pageName: "Reports",
                });
              }
            }}
          >
            {currentReportFilter.state === ReportStates.unprocessed
              ? "See Recently Processed"
              : "See Active Reports"}
          </Button>
        </Box>
      )}

      {!drawerOpen && (
        <ReportTableContainer
          loading={false}
          state={currentReportFilter.state}
          title={
            currentReportFilter.state === ReportStates.unprocessed
              ? "Active Reports"
              : "Processed Reports"
          }
          reports={reports}
          showAlertCount={
            currentReportFilter.state === ReportStates.unprocessed
              ? true
              : false
          }
          onSeeProcessed={(state) => {
            if (state === ReportStates.unprocessed) {
              setCurrentReportFilter((previousState) => ({
                ...previousState,
                state: ReportStates.processed,
              }));
            } else {
              setCurrentReportFilter((previousState) => ({
                ...previousState,
                state: ReportStates.unprocessed,
              }));
            }
          }}
          reportClicked={(report, closeOnly) => {
            setReport(report);
            if (closeOnly) {
              if (drawerOpen) {
                setDrawerOpen(false);
              }
            } else {
              setDrawerOpen(!drawerOpen);
            }
          }}
          deleteReportClicked={(report) => {
            confirmAlert({
              title: `Delete this report?`,
              message: "This cannot be undone. Continue with delete?",
              buttons: [
                {
                  label: "Yes",
                  onClick: async () => {
                    await ReportData.deleteReport(
                      localStorage.getItem("uid"),
                      report.id,
                    );
                    events.logButtonClick({
                      buttonName: "Delete Report",
                      pageName: "Reports",
                    });
                  },
                },
                {
                  label: "No",
                  onClick: () => {},
                },
              ],
            });
          }}
        />
      )}

      {drawerOpen && (
        <ReportSummaryDrawer
          open={drawerOpen}
          report={report}
          // update={update}
          onClose={() => {
            setDrawerOpen(!drawerOpen);
          }}
          handleMarkProcessed={(report) => {
            if (report.status === "unprocessed") {
              ReportData.processReport({ reportId: report.id });
              events.logButtonClick({
                buttonName: "Process Report",
                pageName: "Reports",
                tabName: report,
              });
            } else {
              ReportData.processReport({
                reportId: report.id,
                status: "unprocessed",
              });
              events.logButtonClick({
                buttonName: "Unprocess Protocol",
                pageName: "Protocols",
                tabName: report,
              });
            }
            setDrawerOpen(!drawerOpen);
          }}
        />
      )}
    </Box>
  );
};

export default ReportPage;
